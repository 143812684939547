<template>
  <div :class="`flex flex-col justify-between items-center ${!isMobileDevice ? 'cardladder-container p-7-5' : 'flex-1 p-7-5 sm:p-4'}`">
    <div class="flex flex-col flex-1">
      <div class="flex justify-between">
        <div class="text-center text-xl25 font-bold mx-auto md:text-xl">
          {{$t('closed_beta')}}
        </div>
      </div>
    
      <div class="text-container mt-7-5 text-n-xl xl:mt-7-5 md:text-n-lg">
        {{$t('authentication.join_us_on_discord')}}
      </div>
      <div class="text-container mt-2-4 text-n-xl xl:mt-7-5 md:text-n-lg">
        {{$t('authentication.ios_app')}}
      </div>
      <div class="text-container mt-2-4 text-n-xl xl:mt-7-5 md:text-n-lg">
        {{$t('authentication.android_app')}}
      </div>

      <div class="flex flex-col mt-auto">
        <ButtonV2
          @onClick="goToExternalPageGlobal('https://out.dibbs.io/discord')"
          :label="$t('navigation.join_discord')"
          testId="btn-download-discord"
          version="secondary"
          class="mt-10"
          wide
        />
        <ButtonV2
          @onClick="goToExternalPageGlobal('https://app.dibbs.io/ios')"
          :label="$t('navigation.download_ios')"
          testId="btn-download-ios"
          class="mt-4"
          wide
        />
        <ButtonV2
          @onClick="goToExternalPageGlobal('https://app.dibbs.io/android')"
          :label="$t('navigation.download_android')"
          testId="btn-download-android"
          class="mt-4"
          wide
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ButtonV2 from '@/stories/misc/ButtonV2';

  export default {
    name: 'WebRequiresUserFlag',
    components: {
      ButtonV2,
    },
  };
</script>

<style scoped>
.cardladder-container {
  width: 450px;
}
.text-container {
  @apply flex justify-between items-center text-text-body font-semibold;
}
</style>
